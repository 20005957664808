import React, { useState, useEffect } from 'react';
import {  BrowserRouter as Router, Route, Routes, Navigate   } from "react-router-dom";
import "./App.css";
import { useAppContext } from './AppContext';
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import Free from "./pages/free/Free";
import Commingsoon from "./pages/commingsoon/Commingsoon";
import HowToEvaluateBusinessIdea from "./pages/howtoevaluatebusinessidea/HowToEvaluateBusinessIdea";
/*
import Register from "./Register";
import Reset from "./Reset";
import Dashboard from "./Dashboard";
*/

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { appState, setAppState } = useAppContext();
  
  // Check if the user is authenticated
  useEffect(() => {
    setIsAuthenticated(appState.user != null ? true : false);
  }, [appState]);

  return (
    <div className="App">
      <header className="App-header">
        <div className="grid-container">
        <a href="/" className="App-logo"><img src="/logo96.png" alt="AIdeaProbe logo" /></a>
          <h1 className="App-title">
            <a href="/"><span className="App-name-color-1">AI</span><span className="App-name-color-2">dea</span><span className="App-name-color-3">Probe</span></a>
          </h1>
          <p className="App-description">
            Your Ultimate Business Idea Evaluator
          </p>
        </div>
      </header>

      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          
          <Route
            path="/free"
            element={<Free />}
          />

          <Route
            path="/commingsoon/:productId"
            element={<Commingsoon />}
          />

          <Route
            path="/how_to_evaluate_business_idea"
            element={<HowToEvaluateBusinessIdea />}
          />

          <Route exact path="/login" element={<Login />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
