import React, { useState, useEffect } from 'react';
import './Commingsoon.css';
import products from '../../data/products.json';
import { useParams , Link, Navigate } from 'react-router-dom';
import { useAppContext } from '../../AppContext';

const Commingsoon = () => {
  const { productId } = useParams();
  const [product, setProductData] = useState(null);
  const [interestSubmitted, setInterestSubmitted] = useState(false);
  const { appState, setAppState } = useAppContext();

  const apiRoot = `${process.env.REACT_APP_API_ROOT}`;

  useEffect(() => {
    setProductData(products.find(x => x.id == productId));
  }, []);

  useEffect(() => {
  }, [product]);

  const registerInterest = async (isInterested) => {
    try {
      const response = await fetch(apiRoot + '/interests', {
        method: 'POST',
        body: JSON.stringify({ 
          userId: appState.user.uid, 
          userEmail: appState.user.email, 
          productId: product.id,
          isInterested: isInterested
        }),
        headers: {
          'Content-Type': 'application/json',
        }
      });
    } catch (error) {
      console.error('Error registering interest:', error);
    }
  };

  const handleRegisterInterest = () => {
    registerInterest('Yes');
    setInterestSubmitted(true);
  };

  const handleNotInterested = () => { 
    registerInterest('No');
    setInterestSubmitted(true);
  };

  const renderBasket = () => {
    return (
      <div className="basket-container">
      {product && (
        <>
          {!interestSubmitted ? (
          <>
          <h1>This product is comming soon</h1>
          <div className="product-option">            
            <h2>{product.name} {product.prices ? " - " + product.prices[0].currency + "" + product.prices[0].value + " per evaluation": ""}</h2>
            <ul>
            {product.features.map((feature) => (
              <li dangerouslySetInnerHTML={{ __html: feature }}></li>
            ))}
            </ul>
          </div>
          <div className='center-block'>
            <button onClick={handleRegisterInterest}>Yes, register my interest</button>&nbsp;&nbsp;
            <button onClick={handleNotInterested}>No thanks, I'm not interested</button>
          </div>
          <p>All users who register their interest will get <strong>Priority notifications</strong> and <strong>50% discount</strong> vouchers valid for their <strong>first 5 (five)</strong> Business Idea Evaluations via E-Mail as soon as this product becomes available.</p>
          </>
        ) : (
          <>
          ( <Navigate to="/#business_idea_evaluation_options" /> )
          </>
          )}
        </>)}
      </div>  
    );
  };

  return appState.user ? (
    <>
        {renderBasket()}
    </>
  ) : ( <Navigate to="/login" /> );
};

export default Commingsoon;
