import React from 'react';
import { Helmet } from 'react-helmet';
import './Home.css';
import products from '../../data/products.json';
import { Link } from 'react-router-dom';

const Home = () => {
  const productName = "AIdeaProbe";

  const getCheckoutUrl = (product) => {
    return product.prices ? "/commingsoon/" + product.id : "/" + product.id;
  }

  const renderProducts = () => {
    return (
      <>
      {products && (
        <>
        <a id="business_idea_evaluation_options"></a>
        <h2>Product Options</h2>
        <div className="product-options">
        {products.map((product) => (
          <div className="product-option">
            <Link to={getCheckoutUrl(product)}>
              <button>{product.name} {product.prices ? " - " + product.prices[0].currency + "" + product.prices[0].value + " per evaluation": ""}</button>
            </Link>
            
            <h2>{product.name}</h2>
            <ul>
            {product.features.map((feature) => (
              <li dangerouslySetInnerHTML={{ __html: feature }}></li>
            ))}
            </ul>
          </div>
        ))}
        </div>
        </>
      )}
      </>  
    );
  };

  return (
    <>
      <Helmet>
        <title>Evaluate a Business Idea for Success online with help of AI | AIdeaProbe App</title>
        <meta name="description" content="Our web app asks you simple yet targeted questions to evaluate the viability of your business idea. Unlock the potential of your business venture with AI-powered insights.  Receive a report that breaks down your idea's Chances of Success, Strengths, Weaknesses, Uncertainties, and provides a tailored Action Plan. Take the guesswork out of entrepreneurship and make data-driven decisions for a successful business launch."/>
      </Helmet>
      <div className="chat-container">
          <div className="message user2">
            <p>
              <span className="emoji">👋</span>
              Hey there! I've got this amazing business idea that I'm super excited about! I can't wait to get started and make it big!
            </p>
          </div>

          <div className="message user1">
            <p>
              <span className="emoji">😄</span>
              That sounds fantastic! I'm thrilled you're pumped about it. But you know what they say, "Look before you leap!" Have you considered giving your idea some more thought before diving in headfirst?
            </p>
          </div>

          <div className="message user2">
            <p>
              <span className="emoji">🤔</span>
              Well, I'm pretty confident in my idea, but I guess a second opinion wouldn't hurt. What do you suggest?
            </p>
          </div>

          <div className="message user1">
            <p>
              <span className="emoji">🚀</span>
              That's the spirit! Instead of rushing, why not try {productName}? It's an awesome platform that assesses your business idea without you having to spill all the secrets. It'll help you understand your idea's chances of success and identify any strengths or weaknesses!
            </p>
          </div>

          <div className="message user2">
            <p>
              <span className="emoji">🙌</span>
              Oh, that sounds cool! So, I just answer some questions, and it gives me an evaluation?
            </p>
          </div>

          <div className="message user1">
            <p>
              <span className="emoji">👍</span>
              Exactly! The more honest and thoughtful your answers, the better the evaluation. It'll help you focus on what really matters and reduce risks. A bit of preparation can make all the difference in your entrepreneurial journey!
            </p>
          </div>

          <div className="message user2">
            <p>
              <span className="emoji">🤩</span>
              Count me in! I'm eager to maximize my chances of success. Thanks for looking out for me!
            </p>
          </div>

          <div className="message user1">
            <p>
              <span className="emoji">👏</span>
              You're welcome! That's what friends are for, right? Let's get you started on {productName} 👇👇🏾👇🏻and make sure your business idea is ready to take off like a rocket! 🚀
            </p>
          </div>
        </div>
        {renderProducts()}

      </>
  );
};

export default Home;
