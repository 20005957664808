import './Free.css';
import { marked } from 'marked';
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FaThumbsUp, FaThumbsDown } from 'react-icons/fa';
import { useAppContext } from '../../AppContext';

const Free = () => {
  const stepEvaluationName = 1;
  const stepQuestions = 2;
  const stepResult = 3;
  const stepStart = stepQuestions;
  const steps = stepResult;
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [evaluationName, setEvaluationName] = useState('');
  const [evaluation, setEvaluation] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [result, setResult] = useState(null);
  const [hasAgreed, setHasAgreed] = useState(false); 
  const feedbackTypeLike = 'Like';
  const feedbackTypeDislike = 'Dislike';
  const [feedbackType, setFeedbackType] = useState('');
  const [feedbackComment, setFeedbackComment] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const {appState, setAppState} = useAppContext();
  

  const apiRoot = `${process.env.REACT_APP_API_ROOT}`;

  useEffect(() => {
    // Fetch data when the currentStep changes
  if (currentStep === stepQuestions) {
      fetchQuestions();
    } else if (currentStep === stepResult) {
      fetchResult();
    }
  }, [currentStep]);

  const fetchQuestions = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiRoot + '/evaluations/' + evaluation.id + '/questions/10', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      const initialAnswers = data.questions.map((question) => ({
        groupNumber: question.groupNumber,
        questionNumber: question.questionNumber,
        question: question.question,
        weight: question.weight,
        preferredAnswer: question.preferredAnswer,
        score: 0,
      }));

      setAnswers(initialAnswers);

      setQuestions(data);

      setLoading(false);
      
    } catch (error) {
      console.error('Error fetching questions:', error);
      setLoading(false);
    }
  };

  const fetchResult = async () => {
    setLoading(true);
    try {
      const response = await fetch(apiRoot + '/evaluations/' + evaluation.id + '/answers', {
        method: 'POST',
        body: JSON.stringify(answers),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      setResult(data.result);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching result:', error);
      setLoading(false);
    }
  };

  const submitFeedback = async () => {
    try {
      const response = await fetch(apiRoot + '/evaluations/' + evaluation.id, {
        method: 'PUT',
        body: JSON.stringify({ feedback: { type: feedbackType, comment: feedbackComment } }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Error sumbitting feedback:', error);
    }
  };

  const submitEvaluationName = async () => {
    try {
      const response = await fetch(apiRoot + '/evaluations/' + evaluation.id, {
        method: 'PUT',
        body: JSON.stringify({ name: evaluationName }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } catch (error) {
      console.error('Error setting evaluation name:', error);
    }
  };

  const createEvaluation = async () => {
    try {
      const response = await fetch(apiRoot + '/evaluations', {
        method: 'POST',
        body: JSON.stringify({ user: appState.user.uid }),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      return await response.json();
      
    } catch (error) {
      console.error('Error sumbitting feedback:', error);
    }
  };

  const handleSliderChange = (event, question) => {
    const newScore = parseInt(event.target.value);
    const updatedAnswers = answers.map((answer) => {
      if (
        answer.groupNumber === question.groupNumber &&
        answer.questionNumber === question.questionNumber
      ) {
        return { ...answer, score: newScore };
      }
      return answer;
    });
    setAnswers(updatedAnswers);
  };

  const renderSlider = (question) => {
    return (
      <div key={question.groupNumber + "_" + question.questionNumber} className="slider-container">
        <input
          className="slider"
          type="range"
          min="-5"
          max="5"
          step="1"
          value={answers.find(
            (ans) =>
              ans.groupNumber === question.groupNumber &&
              ans.questionNumber === question.questionNumber
          ).score}
          onChange={(event) => handleSliderChange(event, question)}
        />
        <div className="slider-labels">
          <div className="label no">No</div>
          <div className="label not-sure">Not sure</div>
          <div className="label yes">Yes</div>
        </div>
      </div>
    );
  };


  const handleAgreeChange = (e) => {
    setHasAgreed(e.target.checked); 
  };

  const handleStart = () => {
    startNewEvaluation();
  };

  const handleNext = () => {
    if(currentStep < steps) {
      if(currentStep == stepEvaluationName) {
        submitEvaluationName();
      }
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSubmitFeedback = () => {
      submitFeedback();
      setFeedbackSubmitted(true);
  };

  const startNewEvaluation = async () => {
    const data = await createEvaluation(evaluationName);
    setEvaluation(data.evaluation);
    
    setEvaluationName('');
    setQuestions(null);
    setAnswers(null);
    setResult(null);
    setFeedbackType('');
    setFeedbackComment('');
    setFeedbackSubmitted(false);
    setCurrentStep(stepStart);
};

  const renderStep = () => {
    switch (currentStep) {
      case stepEvaluationName:
        return (
          <div>
            <h1>New evaluation</h1>
            <input 
              type="text" 
              className="simple-text-input"
              value={evaluationName} 
              onChange={e => setEvaluationName(e.target.value)} 
              placeholder="Enter name for a new evaluation"
            />
          </div>
        );
      case stepQuestions:
        return (
          <div>
            <h1>Questions</h1>
            {questions ? (
            <div>
              {questions.groups.map((group) => (
                <div key={group.number}>
                  <h2>{group.group}</h2>
                  {questions.questions
                    .filter((question) => question.groupNumber === group.number)
                    .map((question) => (
                      <div key={question.questionNumber} className="question-container">
                        <p>{question.question}</p>
                        {renderSlider(question)}
                      </div>
                    ))}
                </div>
              ))}
            </div>
            ) : (
              <p>
                <img className="loader"
                src="/loading.gif" alt="Loading..." />
              </p>
            )}
          </div>
        );
      case stepResult:
        return (
          <div>
            <h1>Result</h1>
            {result ? (
              <div>
                <h2>Chances of Success</h2>
                <div>
                  <span className="chancess-of-success-value" style={(result.chancessOfSuccess > 70 ? {color: 'green'} : (result.chancessOfSuccess > 30 ? {color: 'orange'} : {color: 'red'}))}>{result.chancessOfSuccess}%</span>
                </div>
                {result.comments.summary && (
                <div className="summary-container">
                  <h3>Summary</h3>
                  <p>{ result.comments.summary }</p>
                </div>)}
                {result.comments.strengths && result.comments.strengths.length > 0 && (
                <div className="summary-container">
                  <h3>Strengths</h3>
                  <ul>
                  {result.comments.strengths.map((strength) => (
                    <li>{strength}</li>
                  ))}
                </ul>
                </div>)}
                {result.comments.weaknesses && result.comments.weaknesses.length > 0 && (
                <div className="summary-container">
                  <h3>Weaknesses</h3>
                  <ul>
                  {result.comments.weaknesses.map((weakness) => (
                    <li>{weakness}</li>
                  ))}
                </ul>
                </div>)}
                {result.comments.uncertainties && result.comments.uncertainties.length > 0 && (
                <div className="summary-container">
                  <h3>Uncertainties</h3>
                  <ul>
                  {result.comments.uncertainties.map((uncertainty) => (
                    <li>{uncertainty}</li>
                  ))}
                </ul>
                </div>)}
                {result.comments.actions && result.comments.actions.length > 0 && (
                <div className="summary-container">
                  <h3>Actions</h3>
                  <ul>
                  {result.comments.actions.map((action) => (
                    <li>{action}</li>
                  ))}
                </ul>
                </div>)}
                {result.comments.conclusion && (
                <div className="summary-container">
                  <h3>Summary</h3>
                  <p>{ result.comments.conclusion }</p>
                </div>)}
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                {!feedbackSubmitted ? (
                <div>
                  <h3>We value Your Feedback. Please, let us know what you think.</h3>
                  <div className="feedback-buttons">
                    <button
                      className={`feedback-button ${feedbackType === feedbackTypeLike ? 'selected' : ''}`}
                      onClick={() => setFeedbackType(feedbackTypeLike)}
                    >
                      <FaThumbsUp size={20} />
                      It was helpful
                    </button>
                    <button
                      className={`feedback-button ${feedbackType === feedbackTypeDislike ? 'selected' : ''}`}
                      onClick={() => setFeedbackType(feedbackTypeDislike)}
                    >
                      <FaThumbsDown size={20} />
                      It wasn't helpful
                    </button>
                  </div>
                  <div>
                    <textarea 
                      className="feedback-comment-box"
                      rows={10}
                      type="text" 
                      value={feedbackComment} 
                      onChange={e => setFeedbackComment(e.target.value)} 
                      placeholder="Comments (optional)"
                    />
                  </div>
                  <div className='action-buttons'>
                    <button onClick={handleSubmitFeedback} disabled={!feedbackType && feedbackComment === ''}>Submit Feedback</button>
                  </div>
                </div>) : ( 
                  <h3>Thank You for Your Fedback!</h3>
                )}
              </div>
            ) : (
              <p>
                <img className="loader"
                src="/loading.gif" alt="Loading..." />
              </p>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return appState.user ? (
    <>
    <div className="steps-container">
      {renderStep()}
      <div>
        {currentStep == null && (
        <div>
          <h2>Disclaimer</h2>
          <div className="disclaimer-container">
            <h3>General Information</h3>
            <p>
              AIdeaProbe ("the App") is designed to assist you in evaluating the potential success of a business idea based on your responses to a set of questions. 
              The App aims to identify possible Strengths, Weaknesses, and Uncertainties around your business idea and may also provide suggestions for Possible Actions you might consider taking. 
              However, please be advised that the App is provided for illustrative and informational purposes only. 
              It is not intended to be a substitute for professional business advice, consultation, or analysis. 
              The creators, developers, and distributors of AIdeaProbe do not guarantee the success or failure of any business idea and expressly disclaim any liability for the success or failure of your business endeavors.
            </p>

            <h3>No Guarantee of Accuracy or Success</h3>
            <p>
              The evaluations and suggestions provided by the App are generated based on the information you supply. 
              While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, or suitability of the recommendations or analyses provided.
            </p>
            <h3>Limitation of Liability</h3>
            <p>
              By using AIdeaProbe, you acknowledge and agree that any decisions or actions you take based on the information provided by the App are solely at your own risk and discretion. 
              The creators, developers, and distributors of the App shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, or any other intangible losses resulting from the use or inability to use the App's recommendations.
            </p>
            <h3>Interpretation and Use</h3>
            <p>
              The App's recommendations are not to be interpreted as definitive judgments on the viability or potential for success of your business idea. Business success depends on a variety of factors including, but not limited to, market conditions, competition, and execution—factors that are beyond the scope and capability of this App to evaluate.
            </p>
            <h3>User Responsibility</h3>
            <p>
              You are responsible for conducting your own due diligence, consulting with business advisors, and making use of other resources to determine the potential success or failure of your business idea. If you choose to implement any or all of the Possible Actions suggested by the App, you do so entirely at your own risk, and the App's creators, developers, and distributors do not guarantee the success of your business idea as a result.
            </p>
            <p>
              Thank you for your understanding.
            </p>
          </div>
          <div>
            <br />
            <label>
              <input 
                type="checkbox" 
                checked={hasAgreed} 
                onChange={handleAgreeChange} 
              />
              I have read, understood, and agreed to this disclaimer.
            </label>
          </div>
          <div>
            <button onClick={handleStart} disabled={!hasAgreed}>
              Start
            </button>
          </div>
        </div>
        )}
        {currentStep != null && currentStep < steps && (
          <button
            onClick={handleNext}
            disabled={currentStep == stepEvaluationName && evaluationName.trim() == '' ||
              currentStep == stepQuestions && !questions}>
            Next
          </button>
        )}
      </div>
    </div>
    {currentStep != null && (
      <div className='action-buttons'>
      <Link to="/#business_idea_evaluation_options">
        <button style={{ display: currentStep < stepResult || !result ? 'none' : 'block' }}>Start New Evaluation</button>
      </Link>
      </div>
    )}
    </>
  ) :( <Navigate to="/login" /> );
};

export default Free;
