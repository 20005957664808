// AppContext.js
import { createContext, useContext, useState } from 'react';

// Create the context
const AppContext = createContext();

// Create a custom hook to use the context
export function useAppContext() {
  return useContext(AppContext);
}

// Create the ContextProvider component that wraps your App and provides the context
export function AppContextProvider({ children }) {
  const [appState, setAppState] = useState({
    // Initialize your global state here
    user: null,
  });

  return (
    <AppContext.Provider value={{ appState, setAppState }}>
      {children}
    </AppContext.Provider>
  );
}
