import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider } from 'firebase/auth'

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: "aideaprobe.firebaseapp.com",
  projectId: "aideaprobe",
  storageBucket: "aideaprobe.appspot.com",
  messagingSenderId: "838282465816",
  appId: "1:838282465816:web:e404c4e0901ad93d96a96d",
  measurementId: "G-MC4WQPC5Q5"
};
initializeApp(firebaseConfig);
export const auth = getAuth()
export const google = new GoogleAuthProvider()
export const facebook = new FacebookAuthProvider()
export const twitter = new TwitterAuthProvider()