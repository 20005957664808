import './Login.css';
import React, { useState } from 'react'
import { useLocation, useNavigate, Navigate} from 'react-router-dom';
import { useAppContext } from '../../AppContext';
import { FacebookLoginButton, GoogleLoginButton, TwitterLoginButton } from 'react-social-login-buttons';
import {auth, google, facebook, twitter} from '../../config/fire'
import {signInWithPopup, signOut} from 'firebase/auth' 

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { appState, setAppState } = useAppContext();

  const LoginOptions = () => (
    <>
      <div>
        <GoogleLoginButton onClick={() => login(google)} />
      </div>
      <div>
        <FacebookLoginButton onClick={() => login(facebook)} />
      </div>
      <div>
        <TwitterLoginButton onClick={() => login(twitter)} />
      </div>
    </>
  )
  
  const login = async(provider) => {
    const result = await signInWithPopup(auth, provider) 

    setAppState({ ...appState, user: result.user });

    const redirectPath = new URLSearchParams(location.search).get('redirect');

    /*
    if (redirectPath) {
      navigate(redirectPath);
    } else {
      navigate('/'); 
    }
    */

    navigate(-1);
   
  }

  const logout = async() => {
    const result = await signOut(auth)
    setAppState({ ...appState, user: null });
    console.log(result)
  }

  return (
    <div className="login-container">
      {appState.user ? <Navigate to="/" replace /> : <LoginOptions/>}
    </div>
      
  );
}

export default Login;
